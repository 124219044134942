/**
 * Actions enums declarations
 */
module.exports = {
  // store
  CONFIGURATIONS: 'configurations',
  // Router
  GET_FLOW: 'get-flow',
  REDIRECT_TO_CURRENT_FLOW: 'redirect-current-step-flow',
  ROUTE_CHANGE: 'route_change',
  // Requests
  REQUEST_STARTED: 'request_started',
  REQUEST_FINISHED: 'request_finished',
  REQUEST_STARTED_WITHOUT_RENDER: 'request_started_without_render',
  REQUEST_FINISHED_WITHOUT_RENDER: 'request_finished_without_render',
  REQUEST_ERROR: 'request_error',
  REQUEST_CANCEL_TOKEN: 'request_cancel_token',
  REQUEST_CANCELLED: 'request_cancelled',
  // Chunk
  CHUNK_LOADED: 'chunk_loaded',
  // Page
  EMPTY_PAGE: 'empty_page',
  PAGE_LOAD: 'page_load',
  // Step
  CREATE_FLOW: 'create_flow',
  STEP_NEXT: 'step_next',
  STEP_NEXT_ZERO_DOLLAR: 'step_next_zero_dollar',
  STEP_RETRY: 'step_retry',
  STEP_LOGOUT: 'step_logout',
  // CardTokens
  CARD_TOKEN_GENERATE: 'card_token_generate',
  CARD_TOKEN_UPDATE: 'card_token_update',
  // Input Values
  SAVE_INPUT_VALUES: 'save_input_values',
  CURRENT_INPUT_VALUES: 'current_input_values',
  VISIBILITY_INPUT_VALUES: 'visibility_input_values',
  DISABLE_INPUT_VALUES: 'disable_input_values',
  MESSAGE_INPUT_VALUES: 'message_input_values',
  CLEAN_INPUT_VALUES: 'clean_input_values',
  // Credit Card
  CREDIT_CARD_GUESSING: 'credit_card_guessing',
  CREDIT_CARD_PC_NUMBER: 'credit_card_pc_number',
  CREDIT_CARD_PC_NAME: 'credit_card_pc_name',
  CREDIT_CARD_PC_EXPIRATION: 'credit_card_pc_expiration',
  CREDIT_CARD_PC_SECURITY: 'credit_card_pc_security',
  CREDIT_CARD_ISSUER: 'credit_card_issuer',
  CREDIT_CARD_CLEAN: 'credit_card_clean',
  // Identification Card
  IDENTIFICATION_CARD_CHANGE: 'identification_card_change',
  IDENTIFICATION_CARD_TYPE: 'identification_card_type',
  IDENTIFICATION_CARD_FOCUS: 'identification_card_focus',
  IDENTIFICATION_CARD_BLUR: 'identification_card_blur',
  IDENTIFICATION_CARD_CLEAN: 'identification_card_clean',
  // Global Errors
  GLOBAL_ERRORS_ADD: 'global_errors_add',
  GLOBAL_ERRORS_CLEAN: 'global_errors_clean',
  // Loading
  LOADER_TRIGGERED: 'loader_triggered',
  LOADER_STOP_ANIMATION: 'loader_stop_animation',
  LOADER_STOP_ANIMATION_RESET: 'loader_stop_animation_reset',
  LOADER_PAY_BUTTON: 'loader_pay_button',
  // Snackbar
  SNACKBAR_TRIGGER: 'snackbar_trigger',
  // Installments
  INSTALLMENT_UPDATE: 'installment_update',
  INSTALLMENT_CLEAN: 'installment_clean',
  INSTALLMENT_REQUEST: 'installment_request',
  INSTALLMENT_UPDATE_COMBINATION: 'installment_update_combination',
  INSTALLMENT_REQUEST_CLEAN: 'installment_request_clean',
  // Credits Installments
  CREDITS_INSTALLMENT_UPDATE: 'credits_installment_update',
  CREDITS_INSTALLMENT_UPDATE_COMBINATION: 'credits_installment_update_combination',
  CREDITS_INSTALLMENT_CLEAN: 'credits_installment_clean',

  // Select Options
  SAVE_OPTIONS: 'save_options',
  OPTIONS_PAGE: 'options_page',
  // Payment Methods
  // @TODO we should deprecate this action/reducer/component if is no loger used
  SAVE_PAYMENT_METHOD: 'save_payment_method', // used only for PaymentOptionSelector (used in old wallet wiew)
  SAVE_INSTALLMENT: 'save_installment',
  FETCH_PAYMENT_METHODS: 'fetch_payment_methods',
  // Size
  CHANGE_IFRAME_SIZE: 'change_iframe_size',
  // Collapsible
  COLLAPSIBLE_CLICKED: 'collapsible_clicked',
  // Captcha token
  REQUEST_CAPTCHA_TOKEN: 'request_captcha_token',
  SAVE_CAPTCHA_TOKEN: 'save_captcha_token',
  // Modal
  TOGGLE_MODAL_VISIBILITY: 'change_modal_visibility',
  RESET_MODAL_CUSTOM_TITLE: 'reset_modal_custom_title',
  UPDATE_MODAL_CUSTOM_TITLE: 'update_modal_custom_title',
  // Texts
  RESET_DINAMIC_TEXT: 'reset_dinamic_text',
  UPDATE_DINAMIC_TEXT_VALUE: 'update_dinamic_text_value',
  // Animation
  ANIMATION_TOGGLE: 'animation_toggle',
  // Transition redirect
  DYNAMIC_MODAL_DESCRIPTION: 'dynamic_modal_description',
  BLOCKED_POPUP: 'blocked_popup',
  SAVE_ADDRESS_STATE_INFORMATION: 'save_address_state_information',
  SAVE_ADDRESS_CITY_INFORMATION: 'save_address_city_information',
  SAVE_ADDITIONAL_ADDRESS_INFORMATION: 'save_additional_address_information',
  CLEAR_ADDRESS_INFORMATION: 'clear_address_information',
  // Action triggered
  BUTTON_CLICK_TRIGGERED: 'button_click_triggered',
  BUTTON_REQUEST_TRIGGERED: 'button_request_triggered',
  BANK_SEARCH: 'bank_search',
  RESET_BANK_SEARCH: 'reset_bank_search',
  CLEAN_BUTTON_ACTIONS: 'clean_button_actions',
  LOTTIE_ANIMATION: 'lottie_animation',
  TRACKING_LOADED: 'tracking_loaded',
  EXECUTE_TRACKING: 'execute_tracking',
  MERCADOPAGO_SDK_LOADED: 'mercadopago_sdk_loaded',
  MERCADOPAGO_SDK_TOKENIZE_ERRORS: 'mercadopago_sdk_tokenize_errors',
  MERCADOPAGO_SDK_TOKENIZE_ERRORS_CLEAN: 'mercadopago_sdk_tokenize_errors_clean',
  MERCADOPAGO_SDK_SECURE_FIELD_EXCEPTION: 'mercadopago_sdk_secure_field_exception',
  MERCADOPAGO_SDK_SECURE_FIELD_UNMOUNT: 'mercadopago_sdk_secure_field_unmount',
  MERCADOPAGO_SDK_SECURE_FIELD_MOUNT: 'mercadopago_sdk_secure_field_mount',
  MERCADOPAGO_SDK_TOKENIZE_CARD_TOKEN: 'mercadopago_sdk_tokenize_card_token',
  TRACKING_REDIRECT_CHALLENGE: 'tracking_redirect_challenge',
  ESC_CARD: 'esc_card,',
  ESC_VALIDATE: 'esc_validate',
  ESC_CARD_TOKEN: 'esc_card_token',
  ESC_RESET: 'esc_reset',
  // Bottom Sheet
  BOTTOM_SHEET: 'bottom_sheet',
  // combinate payment option
  CHECK_PAYMENT_OPTION: 'check_payment_option',
  UNCHECK_PAYMENT_OPTION: 'uncheck_payment_option',
  UPDATE_DENY_LIST: 'update_deny_list',
  RESET_COMBINATION: 'reset_combination',

  // Opened window
  EXTERNAL_REDIRECT_WINDOW: 'externalRedirectWindow',
};
