const React = require('react');
const Tooltip = require('@andes/tooltip');
const QuestionMark = require('../../../../../components/icons/GenericQuestionMark');

// Constants
const {
  COLORS,
} = require('../../../../../../constants');

const {
  DEVICE_TYPE,
  CHECKOUT_CASE: { PAYMENT, SUBSCRIPTION },
  ANDES_PLACEMENT
} = require('../../../../../../constants/commons');

const {
  SUMMARY_ITEMS_TYPES: {
    COUPON_DISCOUNT,
    PREF_DISCOUNT,
    TAX,
  } } = require('../../../../../../constants/app');

const PAYMENT_SUMMARY_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
};

const getValidSideOfTooltip = ({ deviceType, tooltipContainer }) => {
  const maxSpaceThatBreakTooltip = 65;

  if (deviceType === DEVICE_TYPE.DESKTOP) {
    return ANDES_PLACEMENT.BOTTOM_LEFT;
  }

  if (tooltipContainer.current && tooltipContainer.current.offsetLeft > maxSpaceThatBreakTooltip) {
    return ANDES_PLACEMENT.BOTTOM;
  }

  return ANDES_PLACEMENT.TOP;
};

const DEFAULT_CENTS = 20;
const MOBILE_CENTS = 14;
const DESKTOP_CENTS = 16;

const handleSummaryCents = (cents, deviceType) => {
  if (cents?.length) {
    return DEFAULT_CENTS;
  } if (deviceType === DEVICE_TYPE.MOBILE) {
    return MOBILE_CENTS;
  }
    return DESKTOP_CENTS;

}

const getCouponDetail = (detail, deviceType, isSubscription) => {
  if (isSubscription) {
    return '';
  }

  if (detail && deviceType === DEVICE_TYPE.MOBILE) {
    return detail;
  }

  return '';
}

const getCouponDiscountName = (name, deviceType, translations, isSubscription) => {
  if (!name) {
    return translations.DISCOUNT;
  }

  const containPercentage = name.includes('%');

  if (containPercentage) {
    switch (deviceType) {
      case DEVICE_TYPE.MOBILE:
        if (isSubscription) {
          return `${translations.DISCOUNT} ${name}`;
        }

        return `${name} ${translations.OFF}`;
      case DEVICE_TYPE.DESKTOP:
        return `${translations.DISCOUNT} ${name}`;
      default:
        return translations.DISCOUNT;
    }
  }

  return translations.DISCOUNT;
};

const handleSummaryItemProps = ({ item, summary, translations, deviceType, tooltipContainer, setShowDiscountModal, iconAlignment, isSubscription }) => {
  const { type, name, price, detail } = item;
  const priceSize = handleSummaryCents(summary?.total?.price?.cents, deviceType);

  const baseProps = {
    name,
    price: price?.text_value,
    priceCents: price?.cents,
    priceSize,
    type,
  };

  const getIconForType = (type) => {
    switch (type) {
      case COUPON_DISCOUNT:
        return (
          <QuestionMark onClick={() => setShowDiscountModal(true)} />
        );
      case TAX:
        return (
          <Tooltip
            ref={tooltipContainer}
            className="icon-with-tooltip__content"
            side={getValidSideOfTooltip({ deviceType, tooltipContainer })}
            content={detail}
            mouseLeaveDelay={deviceType === DEVICE_TYPE.MOBILE ? 0 : 2}
          >
            <QuestionMark />
          </Tooltip>
        );
      default:
        return null;
    }
  };


  switch (type) {
    case COUPON_DISCOUNT:
      return {
        ...baseProps,
        nameColor: COLORS.POSITIVE,
        priceColor: isSubscription ? COLORS.POSITIVE : COLORS.PRIMARY,
        icon: getIconForType(type),
        iconAlignment,
        name: getCouponDiscountName(baseProps.name, deviceType, translations, isSubscription),
        nameComplement: getCouponDetail(detail, deviceType, isSubscription),
      };
    case PREF_DISCOUNT:
      return {
        ...baseProps,
        nameColor: COLORS.POSITIVE,
        priceColor: COLORS.POSITIVE,
        name: getCouponDiscountName(baseProps.name, deviceType, translations, isSubscription),
        nameComplement: getCouponDetail(detail, deviceType, isSubscription),
      };
    case TAX:
      return {
        ...baseProps,
        nameColor: COLORS.PRIMARY,
        priceColor: COLORS.PRIMARY,
        icon: getIconForType(type),
        priceSize: deviceType === DEVICE_TYPE.MOBILE ? 14 : 16,
      };
    default:
      return {
        ...baseProps,
        nameColor: COLORS.PRIMARY,
        priceColor: price?.text_value === '' ? COLORS.POSITIVE : COLORS.PRIMARY,
        price: price?.text_value === '' ? translations.FREE : price?.text_value,
      };
  }
};

// Summary mobile functions
const isCollapsible = (itemsLength, transactionType) => itemsLength > 1 && transactionType === PAYMENT;

const getMobileTotal = (summary, transactionType, subscription) => {
  if (isCollapsible(summary?.items?.length, transactionType) || transactionType === SUBSCRIPTION) {
    return {
      name: summary.total.name,
      price: summary.total.price.text_value,
      priceSize: handleSummaryCents(summary?.total?.price?.cents, DEVICE_TYPE.MOBILE),
      priceCents: summary?.total?.price?.cents,
      nameObservation: subscription?.next_invoice ?? '',
      nameObservationColor: 'secondary',
      nameObservationSize: 's',
    }
  }
  return {
    name: summary?.items?.[0]?.name,
    price: summary.total.price.text_value,
    priceSize: handleSummaryCents(summary?.total?.price?.cents, DEVICE_TYPE.MOBILE),
    priceCents: summary?.total?.price?.cents,
  };
}

const hasBrand = (brand) => Boolean(brand && brand.name && brand.avatar);

module.exports = {
  PAYMENT_SUMMARY_ALIGNMENT,
  handleSummaryItemProps,
  isCollapsible,
  getMobileTotal,
  hasBrand,
}
