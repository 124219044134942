import React from 'react';
import { Typography } from '@andes/typography';

import { Theme } from '../../../constants/commons';
import UserAvatar from '../UserAvatar';
import UserDefaultIcon from '../../Icons/UserDefault';
import UserDefaultML from '../../Icons/UserDefaultML';

interface UserInfoProps {
  imageURL?: string | null;
  imageAltText?: string;
  userName: string;
  theme?: Theme;
}

const UserInfo = ({
  theme = Theme.DEFAULT,
  imageURL,
  userName,
  imageAltText,
}: UserInfoProps) => {
  const DefaultUserIcon =
    theme === Theme.YELLOW_BUTTON ? UserDefaultML : UserDefaultIcon;
  const avatar = imageURL ? (
    <UserAvatar altText={imageAltText} image={imageURL} />
  ) : (
    <DefaultUserIcon
      aria-label={imageAltText}
      strokeOpacity={1}
      className="cow-user-info__user-default-icon"
    />
  );

  return (
    <div className="cow-user-info">
      <Typography
        component="span"
        size="s"
        type="body"
        className="cow-user-info__text"
      >
        {userName}
      </Typography>
      {(theme === Theme.DEFAULT || theme === Theme.YELLOW_BUTTON) && avatar}
    </div>
  );
};

export default UserInfo;
