import React from 'react';

import { Thumbnail } from '@andes/thumbnail';

interface UserAvatarProps {
  image: string;
  altText?: string;
}

const UserAvatar = (props: UserAvatarProps) => (
  <Thumbnail className="cow-user-avatar">
    <img src={props.image} alt={props?.altText ?? 'user profile photo'} />
  </Thumbnail>
);

export default UserAvatar;
