import React, { memo } from 'react';

const IconMoreOptions = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  ...props
}: SVGProps) => (
  <svg {...props} className={`cow-icon-more-options ${props.className}`} width={width} height={height}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.994 13.6c.662 0 1.2.537 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.663 0-1.2-.538-1.2-1.2 0-.663.537-1.2 1.2-1.2zm0-4.8c.662 0 1.2.537 1.2 1.2 0 .663-.538 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.663.537-1.2 1.2-1.2zm0-4.8c.662 0 1.2.538 1.2 1.2 0 .663-.538 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.662.537-1.2 1.2-1.2z"
    />
  </svg>
);

export default memo(IconMoreOptions);
