/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');

// Store
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

// Andes Components
const { Message } = require('@andes/message');
const { Title } = require('@andes/typography');

// Cow Components
const Navbar = require('@cow/core-components/components/Navbar');
const PaymentSummary = require('@cow/core-components/components/PaymentSummary');
const CardList = require('@cow/core-components/components/CardList');
const CouponCollapsible = require('@cow/core-components/components/CouponCollapsible');
const CardMercadoCredits = require('@cow/core-components/components/MercadoCreditsBanner');
const KycModal = require('@cow/core-components/components/KycModalV2');
const GenericModal = require('@cow/core-components/components/GenericModal');
const CardSwitch = require('@cow/core-components/components/CardSwitch');
const Brand = require('@cow/core-components/components/Brand');
const CaptchaTerms = require('@cow/core-components/components/CaptchaTerms');
const BackLink = require('@cow/core-components/components/BackLink');
const Legals = require('@cow/core-components/components/Legals');

// Custom Components
const CaptchaV2 = require('../../../../components/CaptchaV2');

// Template Utils
const { handleSummaryItemProps, PAYMENT_SUMMARY_ALIGNMENT } = require('../utils/summary');

// Custom Hooks
const { useActions } = require('../hooks/useActions');
const { useCardList } = require('../hooks/useCardList');

// i18n
const injectI18n = require('nordic/i18n/injectI18n');
const translate = require('../../../../translation');

// Internal Components
const Page = require('../../../../components/Page');
const DynamicContent = require('../../../../components/DynamicContent');
const DiscountTrigger = require('../components/DiscountTrigger');

// Constants
const { CAPTCHA_VERSION, SUMMARY_ITEMS_TYPES, USER_TYPES } = require('../../../../../constants/app');
const {
  ENVIROMENT,
  CHECKOUT_CASE: { SUBSCRIPTION },
  THEME,
  COLORS_BY_STATUS,
  SITE_ID,
  EXTERNAL_URLS,
  ASSETS,
  CHECKOUT_TYPE,
} = require('../../../../../constants/commons');
const { INPUTS_ID, BUTTONS } = require('../../../../../constants/ui');

// Actions
const STEP_ACTIONS = require('../../../../spa/actions/step');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
const ANIMATION_ACTIONS = require('../../../../spa/actions/animations');
const LOADING_ACTIONS = require('../../../../spa/actions/loading');
const REQUEST_ACTIONS = require('../../../../spa/actions/request');
const captchaToken = require('../../../../spa/actions/captchaToken');

// Utils
const {
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
  genericPropsTypes,
  genericDefaultValues,
} = require('../../../../utils/propTypes');
const { createToggleFunctionWithAnimation } = require('../utils/discount');
const { getMessageColor } = require('../utils/subscription');
const { getCompanyTheme } = require('../utils/configurations');
const { isMobile } = require('../../../../utils/webview');
const { getMercadoPagoIconBySiteId } = require('../../../../utils/icons');

const Review = (props) => {
  const translations = translate(props.i18n);
  const {
    flow,
    history,
    stepActions,
    snackbarActions,
    bellowIncentives,
    blockedByCaptcha,
    customCardList,
    customSidebar,
    email,
    emailErrorMessage,
    setEmailErrorMessage,
    browserName,
    siteId,
    requestActions,
    loadingActions,
    loadingPayButton,
    requestLoading,
    loadingStopAnimation,
    captchaConfig,
    captchaToken,
    step_model: {
      transaction_type,
      challenge_instructions,
      payer,
      step_title,
      discount,
      summary,
      main_action,
      payment_methods,
      incentives,
      brand,
      terms_and_conditions,
      subscription,
      alternative_payment_method,
      captcha,
      notification,
      shipping,
      errors,
      navigation,
    },
  } = props;

  const NavbarMenuOptions = {
    title: translations.YOU_ENTERED_AS,
    changeUserLabel: translations.CHANGE_ACCOUNT,
    logOutUserLabel: translations.LOG_OUT,
  };

  const templateCase = props.templateCase || 'default';

  // Ref
  const tooltipContainer = React.createRef();

  // States
  const [discountCodeState, setDiscountCodeState] = React.useState('');
  const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = React.useState(errors?.discount?.code ?? '');
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);
  const [isCouponVisible, setIsCouponVisible] = React.useState(false);
  const [useAlternativePaymentMethod, setUseAlternativePaymentMethod] = React.useState(
    alternative_payment_method?.enabled,
  );
  const progressDone = loadingStopAnimation && requestLoading;
  const SummaryTheme = transaction_type === SUBSCRIPTION || subscription !== null ? THEME.DARK : THEME.LIGHT;
  const hasDiscountCodeApplied = summary.items.find((item) => item.type === SUMMARY_ITEMS_TYPES.COUPON_DISCOUNT);

  // Actions Hook
  const {
    showNotification,
    changePaymentMethod,
    changeInstallments,
    dismissConsumerCredits,
    payConsumerCredits,
    sentDiscountCode,
    changeExtraData,
    kyc,
    pay,
    changeShippingOption,
    changeShippingAddress,
    logout,
  } = useActions({
    siteId,
    browserName,
    flow,
    history,
    stepActions,
    snackbarActions,
    discountCode: discountCodeState,
    setDiscountCodeErrorMessage,
    payer,
    email,
    emailErrorMessage,
    setEmailErrorMessage,
    challengesInstructions: challenge_instructions,
    captcha,
    requestActions,
    captchaToken,
    captchaConfig,
    translations,
    useAlternativePaymentMethod,
    loadingActions,
  });

  // Card List Hook
  const { buildCardList } = useCardList({
    siteId,
    isBppFlow: incentives?.is_bpp_flow,
    translations,
    isGuest: payer.is_guest,
    payer,
    shipping,
    actions: {
      changePaymentMethod,
      changeInstallments,
      changeExtraData,
      changeShippingOption,
      changeShippingAddress,
    },
  });

  // Build Card List Options
  const cardListOptions = buildCardList(payment_methods);

  const getElement = () => document.querySelector('.content-row');
  const getVisibleState = () => isCouponVisible;
  const setVisibleState = (state) => setIsCouponVisible(state);

  const toggleDiscount = createToggleFunctionWithAnimation(getElement, getVisibleState, setVisibleState);

  const toggleAlternativePaymentMethod = () => {
    setUseAlternativePaymentMethod((prevState) => !prevState);
  };

  const handleDiscountCodeInput = (event) => {
    setDiscountCodeState(event.target.value);
    setDiscountCodeErrorMessage('');
  };

  // Notifications
  React.useEffect(() => {
    if (notification) {
      // TODO IMPROVE THIS BEHAVIOR TO MATCH WITH PRODUCTION
      document.getElementById(BUTTONS.DISCOUNT_TRIGGER)?.click();
      setDiscountCodeState('');

      showNotification({ component: notification.message, displayTimeout: 10000 });
    }
  }, [notification]);

  // Discount Animation
  React.useEffect(() => {
    if (discount?.is_applicable) {
      const contentContainer = document.querySelector('.content');
      const contentRowContainer = document.querySelector('.content-row');

      contentContainer.style.position = 'relative';
      contentRowContainer.classList.add('content-row--animated');

      // Set the correct size for the "content" element when the "content-row" be major
      // This is because the "content-row" is using position: absolute and the "content" element is using position: relative (For the animation purpose)
      if (contentRowContainer.offsetHeight > contentContainer.offsetHeight) {
        contentContainer.style.height = `${contentRowContainer.offsetHeight}px`;
      }
    }
  }, []);

  return (
    <Page
      title={props.stepTitle}
      currentStep={`${props.currentStep}_template_${templateCase}`}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <div className="layout layout--new-interface">
        {/* Space for Modals */}
        {!!challenge_instructions?.kyc && (
          <KycModal
            dispatcherSelector={`.${BUTTONS.DESKTOP_PAY}`}
            description={challenge_instructions.kyc.description}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            title={challenge_instructions.kyc.title}
            image={ASSETS.KYC_IMAGE}
            submitLabel={translations.START}
            alternativeOptionLabel={translations.PAY_WITH_ANOTHER}
            onSubmit={kyc}
            onSubmitAlternativeOption={changePaymentMethod}
            className={`kyc-modal--${challenge_instructions.kyc.type}`}
          />
        )}

        {discount?.is_applicable && (
          <GenericModal
            className="discount-modal"
            title={translations.GENERAL_CONDITIONS}
            content={<>{discount?.detail}</>}
            isOpen={showDiscountModal}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            onClose={() => setShowDiscountModal(false)}
            onOpen={() => setShowDiscountModal(true)}
          />
        )}

        {/* Space for Header */}
        {(flow.type !== CHECKOUT_TYPE.EMBED && !payer.is_guest) && (
          <Navbar
            userName={payer.name}
            userEmail={payer.email?.value}
            menuOptions={NavbarMenuOptions}
            imageURL={payer.avatar}
            theme={getCompanyTheme(props.configs?.internalConfigurations)}
            isMLB={siteId === SITE_ID.MLB}
            isMobile={isMobile(props.deviceType)}
            logOut={() => logout({ changeUser: false })}
            changeUser={() => logout({ changeUser: true })}
          />
        )}

        <section className="layout__col-content">
          {/* Space for Step title & Coupon */}
          <div className="top-row">
            <Title component="h3" size="s">
              {step_title}
            </Title>
            <DiscountTrigger
              isApplicable={discount?.is_applicable}
              labelToggle={translations.I_HAVE_A_DISCOUNT}
              discountTriggerId={BUTTONS.DISCOUNT_TRIGGER}
              toggleDiscount={toggleDiscount}
            />
          </div>

          <div className={`content ${payer.is_guest ? 'content--guest' : ''}`}>
            {/* Space for Notifications */}
            {subscription?.free_trial && Object.keys(subscription?.free_trial)?.length && (
              <Message
                className="message__free-trial"
                color={getMessageColor(subscription)}
                hierarchy="quiet"
                title={subscription.free_trial.title}
              >
                {subscription.free_trial.label}
              </Message>
            )}

            <div className="content-row">
              <DynamicContent defaultContent={<CardList items={cardListOptions} />} customContent={customCardList} />
              <DynamicContent
                defaultContent={
                  <>
                    {incentives?.credits && (
                      <CardMercadoCredits
                        title={translations.PURCHASE_NOW_AND_PAY_LATER}
                        description={incentives?.credits?.description || ''}
                        promotion={incentives?.credits?.interest_text || ''}
                        submitLabel={translations.PAY_WITH_MERCADO_CREDIT}
                        onClose={dismissConsumerCredits}
                        onClick={payConsumerCredits}
                      />
                    )}

                    {subscription?.validation && Object.keys(subscription?.validation)?.length && (
                      <Message
                        className="message__payment-validation"
                        color={COLORS_BY_STATUS[subscription.validation.status]}
                        hierarchy="quiet"
                        title={subscription.validation.title}
                      >
                        {subscription.validation.label}
                      </Message>
                    )}

                    {/* Combination Card Switch */}
                    {/* TODO: Use i18n for the text of the 'description' prop of the CardSwitch component (Check with the UX team if the wording exists) */}
                    {useAlternativePaymentMethod !== null && useAlternativePaymentMethod !== undefined && (
                      <CardSwitch
                        description="Usar mi dinero disponible en Mercado Pago cuando no sea posible cobrar de la tarjeta"
                        icon={getMercadoPagoIconBySiteId(siteId, SITE_ID.MLA === siteId)}
                        onChange={toggleAlternativePaymentMethod}
                        value={useAlternativePaymentMethod}
                      />
                    )}
                  </>
                }
                customContent={bellowIncentives} // FIXME -> Challengemos aqui el nombre de la prop o si amerita un nuevo DynamicContent
              />

              {/* Footer messages */}
              <div className="footer-terms">
                {terms_and_conditions?.has_legals && (
                  <Legals
                    // TODO: Create a Help component to avoid mix the siteId with this Legals component (ANK-2811 & ANK-2812)
                    isMCO={siteId === SITE_ID.MCO}
                    isMobile={isMobile(props.deviceType)}
                    isUserLogged={!payer.is_guest}
                    brand={{
                      processByLabel: translations.PROCESSED_BY,
                      image: ASSETS.MERCADOPAGO_LOGO,
                      imageAlt: 'Mercado Pago',
                    }}
                    terms={{
                      acceptLabel: translations.ACCEPT_THE_TERMS,
                      termAndConditionsLabel: translations.TERMS_AND_CONDITIONS,
                      termsAndConditionsLink: EXTERNAL_URLS.LEGAL_TERMS,
                      brandLabel: translations.OF_MP,
                    }}
                    help={{
                      label: translations.HELP_TO_PROTECT_YOUR_PURCHASES,
                      link: EXTERNAL_URLS.PROTECT_YOUR_PURCHASE,
                    }}
                  />
                )}
                {terms_and_conditions?.has_captcha && (
                  <CaptchaTerms
                    protectedByLabel={translations.PROTECTED_BY_RECAPTCHA}
                    privacyLabel={translations.PRIVACY}
                    privacyLink={EXTERNAL_URLS.CAPTCHA_PRIVACY}
                    conditionsLabel={translations.CONDITIONS}
                    conditionsLink={EXTERNAL_URLS.CAPTCHA_TERMS}
                  />
                )}
              </div>

              {/* Back link */}
              {navigation?.back_urls?.failure?.length > 0 && (
                <BackLink label={translations.RETURN_TO_SITE} href={navigation.back_urls.failure} />
              )}
            </div>

            <CouponCollapsible
              value={discountCodeState}
              inputId={INPUTS_ID.DISCOUNT_CODE}
              labelInput={translations.DISCOUNT_CODE}
              description={discountCodeErrorMessage || translations.YOU_CAN_ONLY_USE_ONE_DISCOUNT_CODE_AT_A_TIME}
              submitButtonLabel={translations.APPLY_DISCOUNT}
              style={discountCodeErrorMessage ? 'error' : undefined}
              onClick={sentDiscountCode}
              onChange={handleDiscountCodeInput}
              show={discount?.is_applicable}
            />
          </div>
        </section>

        {/* Summary */}
        <section className="layout__col-sidebar">
          {brand && <Brand image={brand.avatar} name={brand.name} />}

          <DynamicContent
            defaultContent={
              <div className="sidebar--default">
                <PaymentSummary
                  title={summary?.title}
                  items={summary?.items?.map((item) =>
                    handleSummaryItemProps({
                      item,
                      summary,
                      translations,
                      deviceType: props.deviceType,
                      tooltipContainer,
                      setShowDiscountModal,
                      iconAlignment: PAYMENT_SUMMARY_ALIGNMENT.LEFT,
                      isSubscription: transaction_type === SUBSCRIPTION,
                    }),
                  )}
                  total={{
                    name: summary?.total?.name,
                    nameWeight: 'regular',
                    nameObservation: subscription?.next_invoice ?? '',
                    nameObservationColor: 'secondary',
                    nameObservationSize: 'xs',
                    price: summary?.total?.price.text_value,
                    priceWeight: 'regular',
                    priceCents: summary?.total?.price.cents,
                    priceSize: summary?.total?.price?.cents?.length ? 20 : 18,
                    priceObservation: summary?.total?.detail,
                    priceObservationColor: 'positive',
                    helperText: subscription?.description ?? '',
                  }}
                  buttonText={main_action}
                  onPayClick={pay}
                  securityPaymentInformationLabel={incentives?.is_secure_payment ? translations.SECURE_PAYMENT : null}
                  theme={SummaryTheme}
                  companyTheme={getCompanyTheme(props.configs?.internalConfigurations)}
                  bottomContent={
                    <>
                      {captcha?.version === CAPTCHA_VERSION.V2 && (
                        <div
                          className={`payment-summary__bottom-content cow-captcha__container cow-captcha__container--${
                            payer.is_guest ? USER_TYPES.GUEST : USER_TYPES.LOGGED
                          }`}
                        >
                          <CaptchaV2
                            {...props}
                            show={captcha?.version === CAPTCHA_VERSION.V2}
                            captchaRetry={captcha?.is_retry || false}
                          />
                        </div>
                      )}
                    </>
                  }
                  disablePayButton={blockedByCaptcha || (isCouponVisible && !hasDiscountCodeApplied)}
                  payButtonHelperText={subscription?.instructions}
                  loadingButton={loadingPayButton && !progressDone}
                />
              </div>
            }
            customContent={customSidebar}
          />
        </section>
      </div>
    </Page>
  );
};

Review.defaultProps = {
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
  ...genericDefaultValues,
  // States
  email: '',
  browserName: '',
  loadingPayButton: false,
  loadingStopAnimation: false,
  requestLoading: false,
  blockedByCaptcha: false,
  // Elements
  customNotifications: undefined,
  bellowIncentives: undefined,
  customCardList: undefined,
  customSidebar: undefined,
};

Review.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  ...genericPropsTypes,
  // States
  blockedByCaptcha: PropTypes.bool,
  email: PropTypes.string,
  setEmailErrorMessage: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  browserName: PropTypes.string,
  loadingPayButton: PropTypes.bool,
  loadingStopAnimation: PropTypes.bool,
  requestLoading: PropTypes.bool,
  // Elements
  customNotifications: PropTypes.node,
  bellowIncentives: PropTypes.node,
  customCardList: PropTypes.node,
  customSidebar: PropTypes.node,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  animationActions: bindActionCreators(ANIMATION_ACTIONS, dispatch),
  captchaToken: bindActionCreators(captchaToken, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig: state.configurations.captcha,
  browserName: state.configurations.browserName,
  requestLoading: state.request.loading,
  loadingPayButton: state.request.loadingPayButton,
  loadingStopAnimation: state.request.loadingStopAnimation,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Review;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(Review));
}
