import React from 'react';

const UserDefault = ({
  className,
  width = 28,
  height = 28,
  strokeOpacity = 0.25,
  ...props
}: SVGProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeOpacity={strokeOpacity}
      strokeWidth="1.3"
      transform="translate(1 1)"
    >
      <circle cx="13" cy="13" r="13" />
      <circle cx="13" cy="9.1" r="3.9" />
      <path d="M20.8 23.4a7.8 7.8 0 00-15.6 0" />
    </g>
  </svg>
);

export default React.memo(UserDefault);
