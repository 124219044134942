const React = require('react');
const PropTypes = require('prop-types');

const TermsAndConditions = ({ text }) => (
  <div className="terms-and-conditions">{text}</div>
);

TermsAndConditions.propTypes = {
  text: PropTypes.string,
};

TermsAndConditions.defaultProps = {
  text: '',
};

module.exports = React.memo(TermsAndConditions);
