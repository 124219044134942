const CowNavbar = require('@cow/core-components/components/Navbar');
const { connect } = require('react-redux');
const { INTERNAL_CONFIGURATIONS, THEME } = require('../../../constants/app');
const { SITE_ID, ENVIROMENT, DEVICE_TYPE } = require('../../../constants/commons');

const Navbar = (props) => {
  let theme = THEME.DEFAULT;
  if (props.configs?.internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.IN_ML)) {
    theme = THEME.ML;
  }

  if (props.configs?.internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.IN_MP)) {
    theme = THEME.MP;
  }

  if (props.configs?.internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.YELLOW_BUTTON)) {
    theme = THEME.YELLOW_BUTTON;
  }

  return <CowNavbar isMLB={props.configs?.platform?.siteId === SITE_ID.MLB} isMobile={props.deviceType === DEVICE_TYPE.MOBILE} {...props} theme={theme} />;
};

const mapStateToProps = (state) => ({
  configs: state.configurations,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Navbar;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps)(Navbar);
}
