import type { RadioListItemProps } from '@andes/radio-list';

import React from 'react';
import { Text } from '@andes/typography';
import { Accordion } from '@andes/accordion';
import { Card } from '@andes/card';
import { RadioList, RadioListItem } from '@andes/radio-list';

import PaymentMethodLogo from '../PaymentMethodLogo';
import { DeviceType } from '../../constants/commons';
import { getTitle } from '../../utils/getTitle';

type Item = {
  id: string;
  title: string;
  description: string;
  promotionLabel?: string;
  showAdditionalInfo?: boolean;
  collapsedContent?: React.ReactNode;
  paymentMethodId: string;
  fullTitle?: string;
};

export type PaymentMethodCardListProps = {
  currentSelected?: string;
  onChange: (id: string) => void;
  items: Array<Item>;
  withDividers?: boolean;
  deviceType?: DeviceType;
};

const CustomRadioListItem = (
  props: RadioListItemProps & {
    expanded: boolean;
    deviceType: DeviceType;
    collapsedContent: React.ReactNode;
  },
) => (
  <>
    <RadioListItem
      className={`radio-list-item-${props.deviceType}`}
      {...props}
    />
    {props.collapsedContent && (
      <Accordion expanded={props.expanded} extend title={null}>
        <div className="payment-method-card-list__options__content">
          {props.collapsedContent}
        </div>
      </Accordion>
    )}
  </>
);

CustomRadioListItem.wraps = RadioListItem;

export default function PaymentMethodCardList({
  items,
  currentSelected,
  onChange,
  withDividers = false,
  deviceType = DeviceType.DESKTOP,
}: PaymentMethodCardListProps) {
  return (
    <Card className="payment-method-card-list">
      <RadioList
        srLabel="payment-method"
        defaultValue={currentSelected}
        value={currentSelected}
        onChange={(_event, item) => {
          onChange(item.toString());
        }}
        withDividers={withDividers}
      >
        {items.map((method) => (
          <CustomRadioListItem
            expanded={method.id === currentSelected}
            title={getTitle(method.title, method.fullTitle)}
            value={method.id}
            chevron={false}
            deviceType={deviceType}
            description={
              <div className="payment-method-card-list__method">
                <Text size="s" color="secondary">
                  {method.description}
                </Text>

                {method.promotionLabel && (
                  <Text size="s" color="positive">
                    {method.promotionLabel}
                  </Text>
                )}
              </div>
            }
            leftContent={<PaymentMethodLogo method={method.paymentMethodId} />}
            collapsedContent={method.collapsedContent}
          />
        ))}
      </RadioList>
    </Card>
  );
}
