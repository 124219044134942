const {
  ERROR_SPA: {
    CHALLENGE_3DS_ERROR,
    SECURE_FIELD_WRAPPER_ERROR,
    CARD_FORM_FULL_ERROR,
    SECURITY_CODE_FORM_ERROR,
    SPLIT_PAYMENT_ERROR,
    WALLET_CARD_FORM_ERROR,
    WALLET_PAYMENT_ERROR,
    MOBILE_WEBKIT_UTIL_ERROR,
    CUSTOM_TOKENIZATION_ERROR,
    SDK_TOKENIZATION_ERROR,
  },
} = require('../../constants/app');

/**
 * Allow retries
 */
const WHITELISTGROUPERROR = [
  CHALLENGE_3DS_ERROR,
  SECURE_FIELD_WRAPPER_ERROR,
  CARD_FORM_FULL_ERROR,
  SECURITY_CODE_FORM_ERROR,
  SPLIT_PAYMENT_ERROR,
  WALLET_CARD_FORM_ERROR,
  WALLET_PAYMENT_ERROR,
  MOBILE_WEBKIT_UTIL_ERROR,
  CUSTOM_TOKENIZATION_ERROR,
  SDK_TOKENIZATION_ERROR,
];

const isRetryAllowedByGroupError = (groupError) => {
  if (!groupError) {
    return false;
  }
  if (WHITELISTGROUPERROR.includes(groupError)) {
    return true;
  }
  return false;
};

/**
 * Redirect to exception page
 * @param {Object} history
 * @param {string} flowType
 * @param {string} queryParams
 * @param {string} errorCode
 * @param {} error
 */

const navigateToException = (history, flowType, queryParams, errorCode, groupError) => {

  history.push(`/${flowType}/exception/?${queryParams}`, { errorCode, showRetry: isRetryAllowedByGroupError(groupError) });
};

module.exports = { navigateToException, isRetryAllowedByGroupError };
