import type { RefObject } from 'react';

import { useEffect, useRef } from 'react';

function useOutsideClick(callback: () => void) {
  const elementReference = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleClickEvent = (event: MouseEvent) => {
      if (
        elementReference.current &&
        !elementReference.current.contains(event.target as Node)
      ) {
        callback();
      }
    };

    document.addEventListener('click', handleClickEvent, true);

    return () => {
      document.removeEventListener('click', handleClickEvent, true);
    };
  }, [callback, elementReference]);

  return elementReference as RefObject<HTMLDivElement>;
}

export default useOutsideClick;
