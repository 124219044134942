/**
 * Module dependencies
 */
const { combineReducers } = require('redux');
const { routerReducer } = require('react-router-redux');

const request = require('./request');
const page = require('./page');
const configurations = require('./configurations');
const inputValues = require('./inputValues');
const creditCard = require('./creditCard');
const snackbar = require('./snackbar');
const identificationCard = require('./identificationCard');
const globalErrors = require('./globalErrors');
const installment = require('./installment');
const selectOptions = require('./selectOptions');
const size = require('./size');
const paymentMethod = require('./paymentMethod');
const collapsible = require('./collapsible');
const captchaToken = require('./captchaToken');
const modal = require('./modal');
const animation = require('./animation');
const transitionRedirect = require('./transitionRedirect');
const address = require('./address');
const button = require('./button');
const texts = require('./texts');
const tracking = require('./tracking');
const lottie = require('./lottie');
const bankSearch = require('./bankSearch');
const paymentMethods = require('./paymentMethods');
const mercadopagoSdk = require('./mercadopagoSdk');
const trackingRedirect = require('./trackingRedirect');
const esc = require('./esc');
const bottomSheet = require('./bottomSheet')
const combinations = require('./combinations');
const externalRedirectWindow = require('./externalRedirectWindow');

/**
 * Expose reducers
 */
module.exports = combineReducers({
  routing: routerReducer,
  request,
  page,
  configurations,
  inputValues,
  creditCard,
  identificationCard,
  globalErrors,
  snackbar,
  installment,
  selectOptions,
  size,
  paymentMethod,
  collapsible,
  captchaToken,
  modal,
  animation,
  transitionRedirect,
  address,
  button,
  texts,
  lottie,
  tracking,
  bankSearch,
  paymentMethods,
  mercadopagoSdk,
  trackingRedirect,
  esc,
  bottomSheet,
  combinations,
  externalRedirectWindow,
});
