import React from 'react';

// Constants
import { Image } from 'nordic/image';

import { Theme } from '../../constants/commons';

// Icons
import IconWrapper from '../Icons/IconWrapper';

// Components
import NavbarMenuOptions from './NavbarMenuOptions';
import NavbarMenuOptionsForTheme from './NavbarMenuOptionsForTheme';
import MercadoPagoFullLogo from '../Icons/MercadoPagoFullLogo';
import MercadoLibreNewLogo from '../Icons/MercadoLibreNewLogo';
import MercadoLivreNewLogo from '../Icons/MercadoLivreNewLogo';

export type NavbarMenuOptionsProps = {
  title?: string;
  headerWording?: string;
  changeUserLabel?: string;
  logOutUserLabel?: string;
  notMyAccountLabel?: string;
};

export type NavbarProps = {
  userName: string;
  imageURL: string | null;
  imageAltText?: string;
  userEmail: string;
  menuOptions: NavbarMenuOptionsProps;
  theme?: Theme;
  isMLB?: boolean;
  isMobile?: boolean;
  userMaxInitialsLength?: number;
  MenuButton?: {
    contractedArialLabel?: string;
    expandedArialLabel?: string;
  };
  logOut?: () => void;
  changeUser?: () => void;
};

function Navbar({
  userName,
  imageURL,
  imageAltText,
  userEmail,
  logOut,
  changeUser,
  theme = Theme.DEFAULT,
  isMLB = false,
  isMobile = false,
  MenuButton,
  userMaxInitialsLength,
  menuOptions: {
    title = 'Ingresaste como:',
    headerWording = 'Estás pagando con tu cuenta de Mercado Pago.',
    changeUserLabel = 'Cambiar cuenta',
    logOutUserLabel = 'Cerrar sesión',
    notMyAccountLabel = "No soy 'Test User'",
  },
}: NavbarProps) {
  const getBrandLogoByTheme = React.useMemo(() => {
    if (theme === Theme.DEFAULT) {
      return (
        <IconWrapper className="icon-mercadopago-full">
          <MercadoPagoFullLogo />
        </IconWrapper>
      );
    }

    let logoURL;

    if (theme === Theme.YELLOW_BUTTON) {
      if (isMLB) {
        return <MercadoLivreNewLogo />;
      }

      return <MercadoLibreNewLogo />;
    }

    if (theme === Theme.ML) {
      if (!isMobile) {
        if (isMLB) {
          // Mercado Livre
          logoURL =
            'https://http2.mlstatic.com/frontend-assets/px-checkout-frontend/907a145d2b8ba041bb93.png';
        } else {
          // Mercado Libre
          logoURL =
            'https://http2.mlstatic.com/frontend-assets/px-checkout-frontend/9b62a18f0c0672a140d3.png';
        }
      } else {
        logoURL =
            'https://http2.mlstatic.com/frontend-assets/px-checkout-frontend/38fd3bbdea0aee8dbc80.png';
      }
    } else {
      // Mercado Pago
      if (!isMobile) {
        logoURL =
        'https://http2.mlstatic.com/frontend-assets/px-checkout-frontend/aa5cdc1402d718cd7eff.png';
      } else {
        logoURL =
        'https://http2.mlstatic.com/frontend-assets/px-checkout-frontend/76434ab3d5f7bb972ad7.png';
      }
    }

    return (
      <Image
        className={`cow-navbar__content__image cow-navbar__content__image--${isMobile ? 'mobile' : 'desktop'}`}
        src={logoURL}
        alt="Brand Logo"
        lazyload="off"
      />
    );
  }, [theme, isMLB]);

  return (
    <nav className={`cow-navbar cow-navbar--${theme}`}>
      <div className="cow-navbar__content">
        {getBrandLogoByTheme}

        <div className="cow-navbar__content__user_info_and_options">
          {theme === Theme.DEFAULT || theme === Theme.YELLOW_BUTTON ? (
            <NavbarMenuOptions
              theme={theme}
              title={title}
              userName={userName}
              imageURL={imageURL}
              imageAltText={imageAltText}
              changeUserLabel={changeUserLabel}
              logOutUserLabel={logOutUserLabel}
              userEmail={userEmail}
              MenuButton={MenuButton}
              logOut={logOut}
              changeUser={changeUser}
            />
          ) : (
            <NavbarMenuOptionsForTheme
              theme={theme}
              headerWording={headerWording}
              imageURL={imageURL}
              imageAltText={imageAltText}
              userName={userName}
              MenuButton={MenuButton}
              notMyAccountLabel={notMyAccountLabel}
              userMaxInitialsLength={userMaxInitialsLength}
              changeUser={changeUser}
            />
          )}
        </div>
      </div>
    </nav>
  );
}

export default React.memo(Navbar);
