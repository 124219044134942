import type { ReactElement, ReactNode } from 'react';

import React from 'react';
import { List, ListItem } from '@andes/list';
import { Typography } from '@andes/typography';
import { Thumbnail } from '@andes/thumbnail';
import { Card, CardFooter } from '@andes/card';
import { VisuallyHidden } from '@andes/common';

import ShowMoreItemList from './ShowMoreItemList';
import PaymentMethodLogo from '../PaymentMethodLogo';
import { CardListTypeEnum, DeviceType } from '../../constants/commons';
import { getTitle } from '../../utils/getTitle';

export type CardListType = CardListTypeEnum;

export type ListItemProps = {
  id: string;
  withChevron?: boolean;
  onClick?: () => void;
  title?: ReactElement | string;
  description?: ReactNode;
  method?: string;
  promotion?: string;
  className?: string;
  rightContent?: ReactNode;
  fullTitle?: string;
  issuedBy?: string;
};

export type CardListV2Props = {
  idList: string;
  titleList?: string;
  items: Array<ListItemProps>;
  maxItemsToDisplay?: number;
  showMoreItemsLabel?: string;
  withDividers?: boolean;
  selectable?: boolean;
  deviceType: `${DeviceType}`;
  bottomContentList?: ReactNode | string;
  type?: CardListType;
};

function CardListV2({
  idList,
  titleList,
  items,
  maxItemsToDisplay = Infinity,
  showMoreItemsLabel,
  withDividers = true,
  selectable,
  deviceType,
  bottomContentList,
  type = CardListTypeEnum.DEFAULT,
}: CardListV2Props) {
  const listItemPaddingSize = React.useMemo(
    () => (deviceType === DeviceType.MOBILE ? '24' : '32'),
    [deviceType],
  );

  const [showHiddenItems, setShowHiddenItems] = React.useState(false);

  const listItems = React.useMemo<Array<ListItemProps>>(
    () => (showHiddenItems ? items : items.slice(0, maxItemsToDisplay)),
    [items, maxItemsToDisplay, showHiddenItems],
  );

  return (
    <Card shadow={deviceType === DeviceType.MOBILE ? 'elevated' : 'flat'}>
      <List
        id={idList}
        type="default"
        withDividers={withDividers}
        selectable={selectable}
        srLabel={titleList}
      >
        <>
          {listItems.map(
            ({
              id,
              withChevron,
              onClick,
              title,
              description,
              method,
              promotion,
              className = '',
              rightContent,
              fullTitle,
              issuedBy,
            }: ListItemProps) => (
              <>
                <ListItem
                  id={id}
                  key={id}
                  chevron={withChevron}
                  description={
                    (description || promotion) && (
                      <>
                        {description && typeof description === 'string' ? (
                          <Typography
                            component="p"
                            type="body"
                            size="s"
                            color="secondary"
                          >
                            {description}
                          </Typography>
                        ) : (
                          description
                        )}
                        {promotion && (
                          <Typography
                            component="p"
                            type="body"
                            size="s"
                            color="positive"
                            className="list-item-promotion"
                          >
                            {promotion}
                          </Typography>
                        )}
                        {bottomContentList && (
                          <VisuallyHidden>{bottomContentList}</VisuallyHidden>
                        )}
                      </>
                    )
                  }
                  leftContent={
                    method && (
                      <Thumbnail
                        size="40"
                        srLabel={method}
                        className="list-item-asset"
                      >
                        <PaymentMethodLogo
                          method={method}
                          issuedBy={issuedBy}
                        />
                      </Thumbnail>
                    )
                  }
                  rightContent={rightContent}
                  onClick={onClick}
                  title={getTitle(title, fullTitle, rightContent, type)}
                  className={`list-item ${className}`}
                  paddingSize={listItemPaddingSize}
                  rightContentPosition="centered"
                />
              </>
            ),
          )}

          {!showHiddenItems &&
            maxItemsToDisplay !== Infinity &&
            showMoreItemsLabel && (
              <ShowMoreItemList
                onClick={() => setShowHiddenItems(!showHiddenItems)}
                paddingSize={listItemPaddingSize}
                label={showMoreItemsLabel}
              />
            )}
        </>
      </List>

      {bottomContentList && (
        <CardFooter className="card-bottom-list">
          {typeof bottomContentList === 'string' ? (
            <Typography
              component="p"
              type="body"
              size="xs"
              color="secondary"
              aria-hidden
            >
              {bottomContentList}
            </Typography>
          ) : (
            bottomContentList
          )}
        </CardFooter>
      )}
    </Card>
  );
}

export default React.memo(CardListV2);
