const React = require('react');

const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const MessageAOPO = require('../../../../components/MessageAOPO');
const Page = require('../../../../components/Page');
const BackButton = require('../components/BackButton');
const stepActionCreators = require('../../../../spa/actions/step');
const Navbar = require('../../../../components/Navbar');
const Optimus = require('../../../../containers/Optimus');
const CardListLoggedSection = require('../components/CardListLoggedSection');
const PaidWithCashSection = require('../components/PaidWithCashSection');
const PaymentOptionPropTypes = require('../templatePropTypes');

const { Title } = require('@andes/typography');
const { CoachMarks } = require('@andes/coach-marks');
const translate = require('../../../../translation');
const {
  COMMONS: {
    CHECKOUT_TYPE : { MODAL },
    DEVICE_TYPE: { MOBILE },
    ENVIROMENT,
  },
} = require('../../../../../constants');
const { MESSAGE_LOCATIONS } = require('../constants/constant');
const { CHECKOUT_TYPE } = require('../../../../../constants/commons');

const PaymentOptionsDesktopLogged = (props) => {
  const translations = translate(props.i18n);

  const aboveMessage = props.frontendData.regulations?.find(regulation => regulation?.value?.location === MESSAGE_LOCATIONS.above);
  const belowMessage = props.frontendData.regulations?.find(regulation => regulation?.value?.location ===  MESSAGE_LOCATIONS.below);

  return (
    <Page
      title={props.frontendData.pageTitle}
      currentStep={props.currentStep}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <CoachMarks
        run={props.frontendData.showCoachMark}
        disableScrolling
        steps={props.frontendData.stepsCoachMark}
        locale={{
          last: translations.I_UNDERSTAND,
        }}
        onChange={props.frontendData.onChangeCoachMark}
        isMobile={props.deviceType === MOBILE}
      />

      {props.flow.type !== CHECKOUT_TYPE.EMBED && <Navbar {...props.frontendData.navbar} />}

      <section className="payment-option-desktop-screen payment-option-desktop-screen--logged">
        <div className="payment-option-desktop-screen__content">
          <Title component="h1" size="s" className="payment-option-desktop-screen__content-title">
            {props.frontendData.pageTitle}
          </Title>

          {aboveMessage &&
            <MessageAOPO
              title={aboveMessage.value.title}
              message={aboveMessage.value.text}
              closable={aboveMessage.value.closable}
              location={aboveMessage.value.location}
              color={aboveMessage.value.message_type}
            />
          }

          <div className={`${props.checkoutType === MODAL && 'scroller'} payment-option-desktop-screen__content-card-list` } >
            {!props.frontendData.showPayWithCashScreen && (
              <CardListLoggedSection
                i18n={props.i18n}
                hasCombination={props.frontendData.combination}
                paymentByAccountMoney={props.frontendData.paymentByAccountMoney}
                rows={props.frontendData.rows}
                moreThanTwoCards={props.frontendData.moreThanTwoCards}
                creditsAndOpenFinance={props.frontendData.creditsAndOpenFinance}
                otherPaymentMethods={props.frontendData.otherPaymentMethods}
                goToScreen={props.frontendData.goToScreen}
                goToCombinationScreen={props.frontendData.goToCombinationScreen}
                deviceType={props.deviceType}
                showPayWithCashSection={props.frontendData.showPayWithCash}
                isTheOptionToPayWithCash={props.frontendData.isTheOptionToPayWithCash}
              />
            )}

            {props.frontendData.showPayWithCashScreen && (
              <PaidWithCashSection
                {...props.frontendData.payWithCash[props.frontendData.showPayWithCashScreen]}
                onClickMethod={props.frontendData.goToStorePage}
                onNavigationBack={props.frontendData.hidePayWithCash}
              />
            )}

            {belowMessage &&
              <MessageAOPO
                title={belowMessage.value.title}
                message={belowMessage.value.text}
                location={belowMessage.value.location}
                closable={belowMessage.value.closable}
                color={belowMessage.value.message_type}
              />
            }

            {props.frontendData.backButton?.url && props.frontendData.backButton?.title && (
              <BackButton url={props.frontendData.backButton?.url} text={props.frontendData.backButton?.title} />
            )}
          </div>
        </div>

        <Optimus
          components={props.frontendData.summary}
          history={props.history}
          siteId={props.siteId}
          urls={props.urls}
          publicKey={props.publicKey}
          deviceType={props.deviceType}
          captcha={props.captcha}
          hiddenComponents={props.hidden_components}
        />
      </section>
    </Page>
  );
};

PaymentOptionsDesktopLogged.propTypes = PaymentOptionPropTypes;

/* istanbul ignore next: cant test it with tests */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
});

/* istanbul ignore next: cant test it with tests */
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(stepActionCreators, dispatch),
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = PaymentOptionsDesktopLogged;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(PaymentOptionsDesktopLogged);
}
