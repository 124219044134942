const React = require('react');

const SiteSecurityWidget = (props) => {
  React.useEffect(() => {
    const siteSecurityScriptsContainer = document.querySelector('#site-security-scripts');
    if (
      siteSecurityScriptsContainer
      && !document.querySelector('#site-security-widget')
      && props.siteSecurity && props.version === 'checkout'
      && props.siteSecurity.widget
      && props.siteSecurity.session_id
    ) {
      const container = document.createElement('div');
      container.id = 'site-security-widget';
      // reacreate the script template as DOM node
      const inlineScriptFromString = document.createRange().createContextualFragment(props.siteSecurity.widget);
      container.appendChild(inlineScriptFromString);
      siteSecurityScriptsContainer.appendChild(container);
    }
  }, []);

  return null;
};

module.exports = SiteSecurityWidget;
