const {
  THEME: COMPANY_THEME,
  INTERNAL_CONFIGURATIONS,
} = require('../../../../../../constants/app');

const getCompanyTheme = (internalConfigurations) => {
  if(internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.IN_ML)){
    return COMPANY_THEME.ML;
  }

  if(internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.IN_MP)){
    return COMPANY_THEME.MP;
  }

  if(internalConfigurations?.includes(INTERNAL_CONFIGURATIONS.YELLOW_BUTTON)){
    return COMPANY_THEME.YELLOW_BUTTON;
  }

  return COMPANY_THEME.DEFAULT;
};

module.exports = {
  getCompanyTheme,
};
