import React, { useCallback, useState } from 'react';

// Components
import { Button } from '@andes/button';
import { Typography } from '@andes/typography';

// Hooks
import useOutsideClick from '../../../hooks/useOutsideClick';

// Icons
import IconMoreOptions from '../../Icons/MoreOptions';

// Constants
import { Theme } from '../../../constants/commons';

// Custom Components
import UserInfo from '../UserInfo';

interface NavbarMenuOptionsProps {
  theme: Theme;
  imageURL?: string | null;
  imageAltText?: string;
  userName: string;
  title: string;
  userEmail: string;
  changeUserLabel: string;
  logOutUserLabel: string;
  logOut?: () => void;
  changeUser?: () => void;
  MenuButton?: {
    contractedArialLabel?: string;
    expandedArialLabel?: string;
  };
}

function NavbarMenuOptions({
  theme,
  userName,
  imageURL,
  imageAltText,
  title,
  userEmail,
  changeUserLabel,
  logOutUserLabel,
  MenuButton,
  logOut,
  changeUser,
}: NavbarMenuOptionsProps) {
  const [showPopup, setShowPopup] = useState(false);

  const onMenuClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setShowPopup((previousState) => !previousState);
  }, []);

  const closeMenuPopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const groupMenuReference = useOutsideClick(closeMenuPopup);

  return (
    <>
      <UserInfo userName={userName} imageURL={imageURL} imageAltText={imageAltText} theme={theme} />

      <div className="cow-group-menu" ref={groupMenuReference}>
        <Button
          hierarchy="transparent"
          size="medium"
          aria-label={
            showPopup
              ? MenuButton?.expandedArialLabel ?? 'Expanded'
              : MenuButton?.contractedArialLabel ?? 'Contracted'
          }
          className="cow-group-menu__button"
          onClick={onMenuClick}
        >
          <IconMoreOptions
            className="cow-group-menu__button__kebab-menu"
            id="icon-more-options"
          />
        </Button>

        {showPopup && (
          <div className="cow-group-menu__popup" ref={groupMenuReference}>
            <div className="cow-group-menu__popup-option">
              <Typography component="p" type="body" size="m" color="secondary">
                {title}
              </Typography>

              <Typography component="p" type="body" size="m" color="secondary">
                {userEmail}
              </Typography>
            </div>

            <div className="cow-group-menu__popup-option--action">
              <Button
                id="change_account"
                hierarchy="transparent"
                onClick={changeUser}
              >
                {changeUserLabel}
              </Button>
            </div>

            <div className="cow-group-menu__popup-option--action">
              <Button
                id="button_logout"
                hierarchy="transparent"
                onClick={logOut}
              >
                {logOutUserLabel}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(NavbarMenuOptions);
