import Cash from '@andes/icons/Cash24';

import CreditCard from '../Icons/CreditCard';
import TwoCards from '../Icons/TwoCards';
import DebitCard from '../Icons/DebitCard';
import Bank from '../Icons/Bank';
import BoletoBancario from '../Icons/BoletoBancario';
import Caixa from '../Icons/Caixa';
import QR from '../Icons/QR';
import MercadoLibreLogoStandAlone from '../Icons/MercadoLibreLogoStandAlone';
import PagamentoNaLoteria from '../Icons/PagamentoNaLoteria';
import OpenFinance from '../Icons/OpenFinance';
import PixOutline from '../Icons/PixOutline';

const ML_STATIC_URL = 'https://http2.mlstatic.com';
const LOGOS_API_ADMIN_BASE_URL = `${ML_STATIC_URL}/storage/logos-api-admin`;

export type SvgComponent = React.MemoExoticComponent<
  (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element
>;

export const CARD_DEFAULT_ICON = `${ML_STATIC_URL}/frontend-assets/px-checkout-frontend/card/default.svg`;

export const LOGOS: Record<string, string> = {
  visa: `${LOGOS_API_ADMIN_BASE_URL}/9042e980-7503-11ee-b8a0-4d709b67ff2a-xs.svg`,
  visa_white_font: `${LOGOS_API_ADMIN_BASE_URL}/381e84a0-bf7f-11ee-8d02-235bd7db41c7-l.svg`,
  debvisa: `${LOGOS_API_ADMIN_BASE_URL}/2dba7030-7756-11ee-8a21-f7cc79dd73f5-m.svg`,
  master: `${LOGOS_API_ADMIN_BASE_URL}/ed5f3c50-7502-11ee-90cd-f389c3b4dbd4-xs.svg`,
  debmaster: `${LOGOS_API_ADMIN_BASE_URL}/39a93940-7502-11ee-8a21-f7cc79dd73f5-xs.svg`,
  amex: `${LOGOS_API_ADMIN_BASE_URL}/80959e90-74f6-11ee-90cd-f389c3b4dbd4-xs.svg`,
  diners: `${LOGOS_API_ADMIN_BASE_URL}/4f4b8a60-774a-11ee-90cd-f389c3b4dbd4-s.svg`,
  maestro: `${LOGOS_API_ADMIN_BASE_URL}/c20739c0-74ff-11ee-8306-c515c1eba7d0-xs.svg`,
  account_money: CARD_DEFAULT_ICON,
  naranja: `${LOGOS_API_ADMIN_BASE_URL}/7f694630-9ebc-11ee-9af3-afd2eb6c9602-xl.svg`,
  tarshop: `${LOGOS_API_ADMIN_BASE_URL}/b9a25d50-9ebc-11ee-9af3-afd2eb6c9602-s.svg`,
  cabal: `${LOGOS_API_ADMIN_BASE_URL}/ffbb0ed0-74f6-11ee-b8a0-4d709b67ff2a-m.svg`,
  cencosud: `${LOGOS_API_ADMIN_BASE_URL}/2375b160-9ebc-11ee-826f-b79887bc5c6d-m.svg`,
  pagofacil: `${LOGOS_API_ADMIN_BASE_URL}/b333e910-571a-11e8-95d8-631c1a9a92a9-xl@2x.png`,
  argencard: `${LOGOS_API_ADMIN_BASE_URL}/e42ecf50-9ebb-11ee-9af3-afd2eb6c9602-s.svg`,
  rapipago: `${LOGOS_API_ADMIN_BASE_URL}/1b5874c0-9fe1-11e8-babb-6db70bbcef61-xl@2x.png`,
  cordobesa: `${LOGOS_API_ADMIN_BASE_URL}/29ccaa80-7501-11ee-8306-c515c1eba7d0-l.svg`,
  cmr: `${LOGOS_API_ADMIN_BASE_URL}/37b87ae0-7504-11ee-8306-c515c1eba7d0-m.svg`,
  consumer_credits: `${LOGOS_API_ADMIN_BASE_URL}/74c060f0-c197-11ec-9c4a-3f71f911c79a-xl.svg`,
  debcabal: CARD_DEFAULT_ICON,
  codensa: `${LOGOS_API_ADMIN_BASE_URL}/3a987e70-7506-11ee-8a21-f7cc79dd73f5-xs.svg`,
  hipercard: `${LOGOS_API_ADMIN_BASE_URL}/ce209410-7502-11ee-90cd-f389c3b4dbd4-xl.svg`,
  icon_mercado_pago_black: `${LOGOS_API_ADMIN_BASE_URL}/6ff19da0-ae22-11ed-8fee-e544f4286aac-xl.svg`,
  icon_account_money: `${LOGOS_API_ADMIN_BASE_URL}/5ec6cce0-0401-11ef-abe6-ef0735c1c9af-l.svg`,
  pix_am: CARD_DEFAULT_ICON,
  magna: `${LOGOS_API_ADMIN_BASE_URL}/7393d050-7504-11ee-8306-c515c1eba7d0-xl.svg`,
  presto: `${LOGOS_API_ADMIN_BASE_URL}/8dde5020-7504-11ee-8306-c515c1eba7d0-l.svg`,
  redcompra: CARD_DEFAULT_ICON,
  banamex: CARD_DEFAULT_ICON,
  serfin: CARD_DEFAULT_ICON,
  bancomer: CARD_DEFAULT_ICON,
  edenred: CARD_DEFAULT_ICON,
  sivale_despensa: CARD_DEFAULT_ICON,
  sivale_restaurant: CARD_DEFAULT_ICON,
  paycash: CARD_DEFAULT_ICON,
  meliplace: CARD_DEFAULT_ICON,
  oca: `${LOGOS_API_ADMIN_BASE_URL}/8fa8ea40-9ec2-11ee-b569-3f7bbead2156-xs.svg`,
  lider: `${LOGOS_API_ADMIN_BASE_URL}/13c814f0-7505-11ee-b8a0-4d709b67ff2a-xs.svg`,
  creditel: `${LOGOS_API_ADMIN_BASE_URL}/f4f9bab0-7504-11ee-8306-c515c1eba7d0-m.svg`,
  pagoefectivo_atm: CARD_DEFAULT_ICON,
  bapropagos: `${LOGOS_API_ADMIN_BASE_URL}/11cde200-9fe6-11e8-babb-6db70bbcef61-xl@2x.png`,
  cobroexpress: `${LOGOS_API_ADMIN_BASE_URL}/142c7600-9fe7-11e8-babb-6db70bbcef61-xl@2x.png`,
  cargavirtual_ticket: `${LOGOS_API_ADMIN_BASE_URL}/31c991a0-57b9-11e8-8359-5d73691de80c-xl@2x.png`,
  redlink_atm: `${LOGOS_API_ADMIN_BASE_URL}/6209c0e0-571b-11e8-823a-758d95db88db-xl@2x.png`,
  redlink_bank: `${LOGOS_API_ADMIN_BASE_URL}/6209c0e0-571b-11e8-823a-758d95db88db-xl@2x.png`,
  pse: `${LOGOS_API_ADMIN_BASE_URL}/8d161a90-c331-11e8-ad6b-47a846c5c248-l.svg`,
  baloto: `${LOGOS_API_ADMIN_BASE_URL}/f6bfaa40-a60a-11e8-8377-57c8e5114095-xl@2x.png`,
  efecty: `${LOGOS_API_ADMIN_BASE_URL}/17c72310-c340-11e8-ad6b-47a846c5c248-l.svg`,
  baloto_ser: `${LOGOS_API_ADMIN_BASE_URL}/dc167660-c35b-11e8-882a-419930e61b19-l.svg`,
  baloto_472: `${LOGOS_API_ADMIN_BASE_URL}/fa346700-c348-11e8-ad6b-47a846c5c248-l.svg`,
  davivienda: `${LOGOS_API_ADMIN_BASE_URL}/e81e4680-c348-11e8-ad6b-47a846c5c248-l.svg`,
  soriana: `${LOGOS_API_ADMIN_BASE_URL}/dac0bf10-01eb-11ec-ad92-052532916206-l.svg`,
  calimax: `${LOGOS_API_ADMIN_BASE_URL}/52efa730-01ec-11ec-ba6b-c5f27048193b-l.svg`,
  circlek: `${LOGOS_API_ADMIN_BASE_URL}/6f952c90-34ab-11e9-8357-f13e9b392369-l.svg`,
  extra: `${LOGOS_API_ADMIN_BASE_URL}/9c8f26b0-34ab-11e9-b8b8-15cad73057aa-l.svg`,
  farmacias: `${LOGOS_API_ADMIN_BASE_URL}/ab42bb40-34ab-11e9-8357-f13e9b392369-l.svg`,
  casa_ley: `${LOGOS_API_ADMIN_BASE_URL}/4ba6b7e0-34ab-11e9-8357-f13e9b392369-l.svg`,
  telecomm: `${LOGOS_API_ADMIN_BASE_URL}/34140fa0-34ac-11e9-8357-f13e9b392369-l.svg`,
  banamex_atm: `${LOGOS_API_ADMIN_BASE_URL}/8cc6edd0-34ab-11e9-8357-f13e9b392369-l.svg`,
  serfin_bank_transfer: `${LOGOS_API_ADMIN_BASE_URL}/d30a2460-34ab-11e9-b8b8-15cad73057aa-l.svg`,
  serfin_atm: `${LOGOS_API_ADMIN_BASE_URL}/d30a2460-34ab-11e9-b8b8-15cad73057aa-l.svg`,
  serfin_ticket: `${LOGOS_API_ADMIN_BASE_URL}/d30a2460-34ab-11e9-b8b8-15cad73057aa-l.svg`,
  chedraui: `${LOGOS_API_ADMIN_BASE_URL}/53ba6fd0-34ab-11e9-8357-f13e9b392369-l.svg`,
  banamex_bank_transfer: `${LOGOS_API_ADMIN_BASE_URL}/8cc6edd0-34ab-11e9-8357-f13e9b392369-l.svg`,
  bancomer_bank_transfer: `${LOGOS_API_ADMIN_BASE_URL}/9adfd420-351b-11e9-89fe-c7f859164841-l.svg`,
  bancomer_atm: `${LOGOS_API_ADMIN_BASE_URL}/9adfd420-351b-11e9-89fe-c7f859164841-l.svg`,
  oxxo: `${LOGOS_API_ADMIN_BASE_URL}/87075440-571e-11e8-823a-758d95db88db-s.svg`,
  otros: `${LOGOS_API_ADMIN_BASE_URL}/98340770-35ff-11e9-aa33-95fcc1ba1ce0-l.svg`,
  khipu: `${LOGOS_API_ADMIN_BASE_URL}/81087240-3b8e-11e9-aa33-95fcc1ba1ce0-l.svg`,
  servipag: `${LOGOS_API_ADMIN_BASE_URL}/19773b70-3b8e-11e9-aa33-95fcc1ba1ce0-l.svg`,
  webpay: `${LOGOS_API_ADMIN_BASE_URL}/a20f6190-2334-11ea-b36a-d1da7ac79587-l.svg`,
  abitab: `${LOGOS_API_ADMIN_BASE_URL}/85716da0-7bf8-11e9-9888-a566cbf302df-l.svg`,
  redpagos: `${LOGOS_API_ADMIN_BASE_URL}/af3addc0-7bf7-11e9-9888-a566cbf302df-l.svg`,
  bcp_ticket: `${LOGOS_API_ADMIN_BASE_URL}/f2038620-3ddf-11ea-881c-5bb290373f64-l.svg`,
  scotiabank_ticket: `${LOGOS_API_ADMIN_BASE_URL}/503bb3c0-3de0-11ea-a523-6bfcaf159b9c-l.svg`,
  bbva_continental_ticket: `${LOGOS_API_ADMIN_BASE_URL}/9adfd420-351b-11e9-89fe-c7f859164841-l.svg`,
  full_carga_ticket: `${LOGOS_API_ADMIN_BASE_URL}/185b32a0-3de0-11ea-9da8-f71a5d5025ae-l.svg`,
  interbank_ticket: `${LOGOS_API_ADMIN_BASE_URL}/45f47a00-3de0-11ea-a523-6bfcaf159b9c-l.svg`,
  kasnet_ticket: `${LOGOS_API_ADMIN_BASE_URL}/01c3cfc0-3de0-11ea-881c-5bb290373f64-l.svg`,
  western_union_ticket: `${LOGOS_API_ADMIN_BASE_URL}/73e900c0-3de0-11ea-a523-6bfcaf159b9c-l.svg`,
  pagoefectivo: `${LOGOS_API_ADMIN_BASE_URL}/9a6c82a0-5721-11e8-8364-bff51f08d440-xl.svg`,
  bcp: `${LOGOS_API_ADMIN_BASE_URL}/f2038620-3ddf-11ea-881c-5bb290373f64-l.svg`,
  bbva_continental: `${LOGOS_API_ADMIN_BASE_URL}/9adfd420-351b-11e9-89fe-c7f859164841-l.svg`,
  interbank: `${LOGOS_API_ADMIN_BASE_URL}/45f47a00-3de0-11ea-a523-6bfcaf159b9c-l.svg`,
  banbif_ticket: `${LOGOS_API_ADMIN_BASE_URL}/10803df0-3de0-11ea-9da8-f71a5d5025ae-l.svg`,
  scotiabank: `${LOGOS_API_ADMIN_BASE_URL}/503bb3c0-3de0-11ea-a523-6bfcaf159b9c-l.svg`,
  tambo_ticket: `${LOGOS_API_ADMIN_BASE_URL}/6217d880-3de0-11ea-881c-5bb290373f64-l.svg`,
  banbif: `${LOGOS_API_ADMIN_BASE_URL}/10803df0-3de0-11ea-9da8-f71a5d5025ae-l.svg`,
  banesco_bank: `${LOGOS_API_ADMIN_BASE_URL}/1f66a980-cdc3-11ea-a26c-a3e05eb53570-l@2x.png`,
  mercantil_bank: `${LOGOS_API_ADMIN_BASE_URL}/082f8430-cdc3-11ea-a26c-a3e05eb53570-xl@2x.png`,
  icon_mercado_pago: `${LOGOS_API_ADMIN_BASE_URL}/5ec6cce0-0401-11ef-abe6-ef0735c1c9af-l.svg`,
  '7eleven': `${LOGOS_API_ADMIN_BASE_URL}/417ddb90-34ab-11e9-b8b8-15cad73057aa-l.svg`,
  icon_mercado_credito: `${LOGOS_API_ADMIN_BASE_URL}/74c060f0-c197-11ec-9c4a-3f71f911c79a-xl.svg`,
  yape: `${LOGOS_API_ADMIN_BASE_URL}/31605570-0279-11ef-9b6c-1b7007e9d17c-xl@2x.png`,
  citibanamex: `${LOGOS_API_ADMIN_BASE_URL}/6d198fa0-3a6e-11ef-99a7-2d9e35254841-l.svg`,
};

export const LOGOS_SVG: Record<string, SvgComponent> = {
  new_card_row: CreditCard as SvgComponent,
  generic_payment_card_icon: CreditCard as SvgComponent,
  split_cards_row: TwoCards as SvgComponent,
  debit_and_prepaid_card_row: DebitCard as SvgComponent,
  ticket: Cash as SvgComponent,
  clabe: Bank as SvgComponent,
  bank_transfer: Bank as SvgComponent,
  bolbradesco: BoletoBancario as SvgComponent,
  caixa_card_row: Caixa as SvgComponent,
  elo: Caixa as SvgComponent,
  debelo: Caixa as SvgComponent,
  icon_loteria: PagamentoNaLoteria as SvgComponent,
  pec: PagamentoNaLoteria as SvgComponent,
  icon_qr: QR as SvgComponent,
  icon_mercado_libre: MercadoLibreLogoStandAlone as SvgComponent,
  pix: PixOutline as SvgComponent,
  pix_opf: OpenFinance as SvgComponent,
};
