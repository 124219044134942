import React, { useCallback, useState } from 'react';

// Andes Components
import { Button } from '@andes/button';
import { Text, Typography } from '@andes/typography';
import { Thumbnail } from '@andes/thumbnail';

// Hooks
import useOutsideClick from '../../../hooks/useOutsideClick';

// Constants
import { Theme } from '../../../constants/commons';

// Icons
import MercadoPagoLogo from '../../Icons/MercadoPagoLogo';
import UserDefaultIcon from '../../Icons/UserDefault';

// Custom Components
import UserAvatar from '../UserAvatar';
import UserInfo from '../UserInfo';

// Utils
import { getInitials } from '../../../utils/string';

interface NavbarMenuOptionsForThemeProps {
  theme: Theme;
  headerWording: string;
  imageURL?: string | null;
  imageAltText?: string;
  userName: string;
  notMyAccountLabel: string;
  MenuButton?: {
    contractedArialLabel?: string;
    expandedArialLabel?: string;
  };
  userMaxInitialsLength?: number;
  changeUser?: () => void;
}

function NavbarMenuOptionsForTheme({
  theme,
  imageURL,
  imageAltText,
  headerWording,
  userName,
  MenuButton,
  notMyAccountLabel,
  userMaxInitialsLength,
  changeUser,
}: NavbarMenuOptionsForThemeProps) {
  const [showPopup, setShowPopup] = useState(false);

  const onMenuClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setShowPopup((previousState) => !previousState);
  }, []);

  const closeMenuPopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const groupMenuReference = useOutsideClick(closeMenuPopup);

  const avatar = imageURL ? (
    <UserAvatar image={imageURL} />
  ) : (
    <Thumbnail className="cow-user-avatar--initials">
      <span>{getInitials(userName, userMaxInitialsLength || 1)}</span>
    </Thumbnail>
  );

  return (
    <div className="cow-group-menu" ref={groupMenuReference}>
      <Button
        hierarchy="transparent"
        size="medium"
        aria-label={
          showPopup
            ? MenuButton?.expandedArialLabel ?? 'Expanded'
            : MenuButton?.contractedArialLabel ?? 'Contracted'
        }
        className="cow-group-menu__button"
        onClick={onMenuClick}
      >
        <UserInfo userName={userName} imageURL={imageURL} imageAltText={imageAltText} theme={theme} />

        <span
          className={`button__chevron ${
            showPopup
              ? 'button__chevron--expanded'
              : 'button__chevron--contracted'
          }`}
        />
      </Button>

      {showPopup && (
        <div className="cow-group-menu__popup" ref={groupMenuReference}>
          <div className="cow-group-menu__popup-option--header">
            <MercadoPagoLogo
              ariaLabel="Mercado Pago Logo"
              width={27}
              height={22}
            />

            <Text component="p" size="xs" color="secondary">
              {headerWording}
            </Text>
          </div>

          <div className="cow-group-menu__popup-option--body">
            {avatar}

            <Text component="p" size="s" color="secondary">
              {userName}
            </Text>
          </div>

          <div className="cow-group-menu__popup-option--footer">
            <Typography
              id="button_not_my_account"
              className="not-my-account"
              onClick={changeUser}
            >
              {notMyAccountLabel}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(NavbarMenuOptionsForTheme);
