const React = require('react');
const PropTypes = require('prop-types');
const { TextField } = require('@andes/textfield');

const InputEmail = React.forwardRef((props, ref) => {
  const ariaInvalid = props.modifier === 'error';
  return <TextField data-testid="email" className="input__email" {...props} ref={ref} aria-invalid={ariaInvalid} />;
});

InputEmail.displayName = 'InputEmail';

InputEmail.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helper: PropTypes.string,
  modifier: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

module.exports = InputEmail;
