/* eslint-disable react/no-unused-state */
const React = require('react');
const PropTypes = require('prop-types');

const merge = require('deepmerge');

const { getErrorAndValidate, getErrorProps } = require('../../../containers/Optimus/helper');

class BaseGroup extends React.Component {
  constructor(props) {
    super(props);
    // This array will contain the childs that need to validate
    this.childrenToValidate = [];
    // Bind methods
    this.validate = this.validate.bind(this);
    this.validateCallback = this.validateCallback.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
  }

  /**
   * Check if the props change and compare them with the state
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateErrors(nextProps.globalErrors);
  }

  /**
   * This method is going to concatenate the errors on prop with the error on the props of the childrens
   * Is going to be generating a unique errors array that contains all of the group + children errors
   *
   * This for the initial render ONLY. Then, you need to get the errors from the state
   *
   * IMPORTANT:
   * This is going to work, because error and invalid is going to be always populated by the Optimus helper (see file helper.js)
   * @returns {Array}
   */
  getPropErrors() {
    let groupErrors = this.props.error || [];

    if (this.props.children) {
      // This prevents sending only one children
      const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];

      children.forEach((c) => {
        // If the children is invalid (have error) and if its not a group (dont get the errors of another group)
        if (c.props.invalid && !(typeof c.type === 'function' && c.type.name.toLowerCase().match('^group'))) {
          groupErrors = groupErrors.concat(c.props.error);
        }
      });
    }

    return groupErrors;
  }

  /**
   * Get child errors messages
   * @returns {Array}
   */
  getChildrenErrors() {
    return this.childrenToValidate.map(child => child.error[0]).filter(e => !!e);
  }

  /**
   * Get child values
   * @returns {Array}
   */
  getChildrenValues() {
    return this.childrenToValidate.map(c => c.value);
  }

  /**
   * Update the state with the errors coming from event (Only applies on CardFormView)
   */
  updateErrors(globalErrors) {
    if (globalErrors) {
      // Only updates if the id received is the one to update
      const error = globalErrors[this.props.id];

      if (error) {
        const errorProps = getErrorProps({ errors: this.props.errors, validations: error });
        this.setState({
          error: errorProps.error,
          invalid: errorProps.invalid,
        });
      }
    }
  }

  /**
   * Check if the children are valid (using invalid)
   * This exists because, if the component show the errors, the error message is going to be empty
   * @returns {boolean}
   */
  areChildrenValid() {
    return (this.childrenToValidate.filter(child => child.invalid).length > 0);
  }

  /**
   * Clean childsToValidate to prevent duplicated errors
   */
  clearChildValidations() {
    this.childrenToValidate = [];
  }

  /**
   * This method could be call from the childs to let know about the results of the validations
   * @param component
   */
  validateCallback(component) {
    this.childrenToValidate.push(component);
  }

  /**
   * Common group validate method
   * This method will check the children for errors. Then it would check the values concat and his own validations (if the childrens are valid)
   * @returns {boolean}
   */
  validate() {
    let groupErrors = [];
    const childrenErrors = this.getChildrenErrors();
    const childrenValues = this.getChildrenValues();

    // If there are no child errors check group regex
    if (!this.areChildrenValid()) {
      // Join the values with an array
      const mergedValue = childrenValues.join('-');
      const errorId = getErrorAndValidate(this.props.validations, mergedValue);

      if (this.props.errors[errorId]) {
        groupErrors.push(this.props.errors[errorId]);
      }
    }

    groupErrors = groupErrors.concat(childrenErrors);

    this.setState({
      error: groupErrors,
      invalid: groupErrors.length > 0,
    });

    this.clearChildValidations();

    return (groupErrors.length === 0);
  }

  /**
   * Render element and pass a validate callback to return the result to the parent group
   * @param element
   * @param showError
   * @param extraProps
   * @returns {Array}
   */
  renderChild(element, showError = false, extraProps = {}) {
    const toRender = Array.isArray(element) ? element : [element];
    return toRender.map((child) => {
      // Merge it, or the value is going to be a reference
      const props = merge.all([{}, child.props, extraProps]);
      // Send the validateCallback as prop
      props.validateCallback = this.validateCallback;
      props.key = child.key; // Copy the key
      // If it is in a group do not show error (default) unless it is specified
      props.showErrorMessage = showError;
      return React.createElement(child.type, props);
    });
  }

  render() {
    return (React.Children.only(this.props.children));
  }
}

BaseGroup.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  globalErrors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

BaseGroup.defaultProps = {
  error: [],
  globalErrors: {},
};

module.exports = BaseGroup;
