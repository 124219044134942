export enum DeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

export enum Theme {
  DEFAULT = 'default',
  ML = 'ml',
  MP = 'mp',
  YELLOW_BUTTON = 'boton_amarillo',
}

export enum PaymentSummaryTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum CardListTypeEnum {
  DEFAULT = 'Default',
  INSTALLMENTS = 'Installments',
}
