import type { ThumbnailProps } from '@andes/thumbnail';
import type { SvgComponent as SvgComponentType } from './logos';

import React from 'react';
import { Image as NordicImage } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';

import { LOGOS, LOGOS_SVG, CARD_DEFAULT_ICON } from './logos';

export type PaymentMethodLogoProps = {
  method: string;
  issuedBy?: string;
  size?: ThumbnailProps['size'];
};

export { LOGOS, LOGOS_SVG } from './logos';

const Image = ({
  method: methodProp,
  issuedBy,
}: Pick<PaymentMethodLogoProps, 'issuedBy' | 'method'>) => {
  let method = methodProp;

  if (method === 'visa' && issuedBy === 'mercado-pago') {
    method = 'visa_white_font';
  }

  const SvgComponent = React.useMemo<SvgComponentType | undefined>(
    () => LOGOS_SVG[method],
    [method],
  );

  const getImageSource = () => {
    const isImageUrl =
      /^(https?:\/\/.*\.(jpg|jpeg|png|gif|bmp|webp|tiff|svg))$/i.test(method);

    if (isImageUrl) {
      return {
        source: method,
        className: 'payment-method-logo__image--external_image',
      };
    }

    return {
      source: LOGOS[method] || CARD_DEFAULT_ICON,
      className: `payment-method-logo__image--${method}`,
    };
  };

  if (SvgComponent) {
    return <SvgComponent className={`payment-method-logo__image--${method}`} />;
  }

  const { source, className } = getImageSource();

  return (
    <NordicImage
      src={source}
      alt={method}
      className={className}
      lazyload="off"
    />
  );
};

export default function PaymentMethodLogo({
  method,
  size = '40',
  issuedBy = '',
}: PaymentMethodLogoProps) {
  return (
    <Thumbnail
      modifier="circle"
      size={size}
      className={`payment-method-logo ${method}${
        issuedBy ? ` ${method}-${issuedBy}` : ''
      }`}
    >
      <Image method={method} issuedBy={issuedBy} />
    </Thumbnail>
  );
}
